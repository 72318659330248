import React, { useState } from 'react';
import {
  MDBNavbar, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBCollapse, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem,
  MDBHamburgerToggler
} from 'mdbreact';
import { useHistory } from "react-router-dom";

const Mobilemenu = () => {
  
  const history = useHistory();

  const [state, setState] = useState({
    collapse1: false,
    collapseID: ''
  });

  const toggleCollapse = collapseID => () => {
    setState(prevState => ({ collapseID: (prevState.collapseID !== collapseID ? collapseID : '') }));
  }

  const toggleSingleCollapse = collapseId => {
    setState({
      ...state,
      [collapseId]: !state[collapseId]
    });
  }

  return (

    <MDBNavbar>
      <MDBHamburgerToggler id="hamburger1" onClick={() => toggleSingleCollapse('collapse1')} />
      <MDBCollapse isOpen={state.collapse1} navbar>
        <MDBNavbarNav left>
          <MDBNavItem active>
            <MDBNavLink to="/">Home</MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink to="/about-us">About Us</MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink to="/our-services">Our services</MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink to="/careers">Careers</MDBNavLink>
            {/* <MDBDropdown>
                        <MDBDropdownToggle nav caret>
                            <span>Careers</span>
                        </MDBDropdownToggle>
                        <MDBDropdownMenu>
                            <MDBDropdownItem href='/careers'>Open Positions</MDBDropdownItem>
                            <MDBDropdownItem href='/life-at-cognitude'>Life At Cognitude</MDBDropdownItem>
                        </MDBDropdownMenu>
                    </MDBDropdown> */}
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink to="/contact">Contact Us</MDBNavLink>
          </MDBNavItem>
        </MDBNavbarNav>
      </MDBCollapse>
    </MDBNavbar>

  );
}

export default Mobilemenu;