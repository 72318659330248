import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import { useHistory } from "react-router-dom";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

const Menu = () => {

    const history = useHistory();

    return (
        <nav id="menu" role="navigation" className="menu">
            <ul>
                <li className={`menu ${(history.location.pathname === "/") ? 'active' : ''}`}><Link to="/">Home</Link></li>
                <li className={`menu ${(history.location.pathname === "/about-us") ? 'active' : ''}`}><Link to="/about-us">About Us</Link></li>
                <li className={`menu ${(history.location.pathname === "/our-services") ? 'active' : ''}`}><Link to="/our-services">Our services</Link></li>
                <li className={`menu ${(history.location.pathname === "/careers") ? 'active' : ''}`}><Link to="/careers">Careers</Link>
                    {/* <ul className="menu-dropdown">
                            <li><Link to="/careers">Open Positions</Link></li>
                            <li><Link to="/life-at-cognitude">Life at Cognitude</Link></li>
                        </ul> */}
                </li>
                <li className={`menu ${(history.location.pathname === "/contact") ? 'active' : ''}`}><Link to="/contact">Contact Us</Link></li>
            </ul>
        </nav>
    )

}

export default Menu;