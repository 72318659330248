import React from 'react';

import Header from '../layout/Header';
import { Footer } from '../layout/Footer';

export default function OurServices(props) {

  return (
    <>
    <div className="site-main">
      <Header />
      {/* page-title */}
      <div className="ttm-page-title-row">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">Our Services</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* our-services-section */}
      <section className="ttm-row clearfix ">
        <div className="container-xl">
          <div className="row no-gutters align-items-start">
            <div className="col-lg-6 res-991-text-center res-991-mb-20">
              <div className="ttm_single_image-wrapper">
                <img
                  className="img-fluid"
                  src="images/teamwork.jpg"
                  title="single-img-five"
                  alt="single-img-five"
                  loading='lazy'
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="aboutus-spacing-2" style={{ marginTop: 25 }}>

                <div className="section-title with-desc clearfix">
                  <div className="title-desc text-justify">
                    <div className='mb-4'>
                      <div className="title-header mb-2">
                        <h5> Custom Application Development:</h5>
                      </div>
                      <p className='mb-2'>Develop bespoke applications for e-commerce, tech, lifestyle, and more, ensuring they align perfectly with your business objectives. </p>
                      <p className='mb-2'>Create unique, branded websites and mobile applications that reflect your company’s identity and resonate with your target audience. </p>
                      {/* <p> From concept to
                              deployment, we deliver end-to-end website development services that align with your brand and objectives. </p> */}
                    </div>
                    <div className='mb-4'>
                      <div className="title-header mb-2">
                        <h5>Mobile Application Development:</h5>
                      </div>
                      <p className='mb-2'>We specialize in creating innovative mobile apps across various platforms, enhancing user engagement and meeting your specific requirements. </p>
                    </div>
                    <div className='mb-4'>
                      <div className="title-header mb-2">
                        <h5>Support & Maintenance Services:</h5>
                      </div>
                      <p className='mb-2'>We provide ongoing support and maintenance for applications and infrastructure, ensuring optimal performance and reliability over time. </p>
                      <p className='mb-2'>Integrate new technologies and processes seamlessly into your existing systems, ensuring compatibility and efficiency. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* our-solution-section */}
      <section className="ttm-row clearfix ">
        <div className="container-xl">
          <div className="row no-gutters align-items-start">
            <div className="col-lg-6 res-991-mb-20">
              <div className="section-title with-desc clearfix">
                <div className="title-header mb-10" style={{ marginTop: 25 }}>
                  <h2 className="title">
                    Our Solutions
                  </h2>
                  <div className="under-title"></div>
                </div>
                <div className="title-desc text-justify line-space1 pt-4">
                  <div className="title-desc">
                    <div className='mb-4'>
                      <div className="title-header mb-2">
                        <h5>German Telecom Giant:</h5>
                      </div>
                      <p className='mb-2'>Retailer App- Allows retailers to collect customer data for SIM card activation.CRM Web & Mobile Application- Streamlines customer relationship management.</p>
                    </div>
                    <div className='mb-4'>
                      <div className="title-header mb-2">
                        <h5>Voucher Management System (VMS):</h5>
                      </div>
                      <p className='mb-2'>Facilitates recharge of telephone cards, vouchers, and
                      travel reservations in a unified system. </p>
                    </div>
                    <div className='mb-4'>
                      <div className="title-header mb-2">
                        <h5>Point of Sale Management System:</h5>
                      </div>
                      <p className='mb-2'>Manages point of sale and cashier applications efficiently.</p>
                    </div>
                    <div className='mb-4'>
                      <div className="title-header mb-2">
                        <h5>Video Conferencing:</h5>
                      </div>
                      <p className='mb-2'>Enables hosting of video conferencing meetings seamlessly.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="aboutus-spacing-2">
                <div className="ttm_single_image-wrapper">
                  <img
                    className="img-fluid"
                    src="images/our-solution.jpg"
                    title="single-img-five"
                    alt="single-img-five"
                    loading='lazy'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer />
    </>
  );
}

