import React, { useEffect, useState } from 'react';

import axios from "axios";
import Header from '../layout/Header';
import Pagination from "react-js-pagination";
import ReactLoading from "react-loading";
import CareerItem from '../careers/CareerItem';

export default function LifeAtCognitude(props) {

  const { REACT_APP_CGN_API_URL, REACT_APP_BEARER_TOKEN } = process.env;
  const [jobsList, setJobsList] = useState([]);
  const [pageDetails, setPageDetails] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [dataReceived, setDataReceived] = useState(undefined);

  useEffect(() => {
    async function fetchJobs() {
      const endpoint = `${REACT_APP_CGN_API_URL}api/v1/jobs?page=${pageNumber}`;
      const headers = {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
        "Authorization": `Bearer ${REACT_APP_BEARER_TOKEN}`
      };

      await axios({
        url: endpoint,
        method: 'get',
        headers: headers
      }).then((response) => {
        // console.log(response.data.data);
        setJobsList(response.data.data);
        setPageDetails(response.data.meta);
        setDataReceived(true);
      }).catch((error) => {
        if (error.response) {
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
      });
    }
    fetchJobs();
  }, [pageNumber]);

  const nextPage = (event) => {
    const selectedPage = event;
    setPageNumber(selectedPage);
  };

  return (
    <div className="site-main">
        <Header />
        <div className="ttm-page-title-row">
            <div className="container-xl">
            <div className="row">
                <div className="col-md-12"> 
                <div className="title-box text-center">
                    <div className="page-title-heading">
                      <h1 className="title">Life at Cognitude</h1>
                    </div>
                </div>
                </div>
            </div>
            </div>                     
        </div>    

        <section className="ttm-row career-section clearfix pb-4">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12 text-justify">
                <p>
                  {/* <span className='cog'>COGN<span className='cog1'>iTUDE</span> </span> */}
                  Do you know what makes us different from others? At Cognitude, we know how to strike the right balance between work and life. We believe the key to success is enjoying life as much as we enjoy our work. Our team members are exposed to great learning experience. Change is a constant throughout your career at Cognitude.You can seek even greater depth within your expertise, change scope in your current position, or even explore a new opportunity here. Whatever you decide, you’ll play the lead role in your growth, and we’ll be behind you all the way. For everything we create, we consider its impact — on our customers, our colleagues, and our planet. The same innovation that goes into creating applications for our clients.</p>
                <p>We believe in a safe, respectful and supportive workplace for everyone.</p>
                <p>Ethical work culture. We believe in open, intellectually honest culture where everyone contribute and do their life's best work.</p>
              </div>
            </div>
        </div>
      </section>
      
      <section className="ttm-row career-section clearfix">
        <div className="container-xl">
        <h2 className="mb-5">Open Positions</h2>
          {!dataReceived ? (
            <div className="component-loader">
              <ReactLoading
                type={"bubbles"}
                color={"#263045"}
                height={100}
                width={100}
              />
            </div>
          ) : (
            <React.Fragment>
              <div className="career-lists open-position-css mx-auto">
                {jobsList
                  ?.map((jobItem, i) => (
                    <CareerItem jobItem={jobItem} key={i} />
                  ))}
              </div>
              {jobsList.length == 0 && dataReceived &&
                <div className="text-center"><h3>Sorry, there are no open open positions at this time</h3></div>
              }
              {pageDetails && pageDetails.total > 10 &&
                <div className="career-pages ttm-pagination">
                  <Pagination
                    activePage={pageDetails.current_page}
                    totalItemsCount={pageDetails.total}
                    itemsCountPerPage={pageDetails.per_page}
                    onChange={nextPage}
                    itemClass="page-item"
                    linkClass="page-numbers"
                    activeLinkClass="current"
                    firstPageText="First"
                    lastPageText="Last"
                  />
                </div>
              }
            </React.Fragment>
          )}
        </div>
      </section>
    </div>
  );
}