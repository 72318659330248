import React, { useEffect, useState } from 'react';

import axios from "axios";
import Pagination from "react-js-pagination";
import ReactLoading from "react-loading";

import Header from '../layout/Header';
import { Footer } from '../layout/Footer';
import CareerItem from './CareerItem';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

export default function CareersList(props) {
  const { REACT_APP_CGN_API_URL, REACT_APP_BEARER_TOKEN } = process.env;
  const [jobsList, setJobsList] = useState([]);
  const [pageDetails, setPageDetails] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [dataReceived, setDataReceived] = useState(undefined);

  const [showServiceSection, setShowServiceSection] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowServiceSection(true);
    }, 1000);
  }, []);

  useEffect(() => {
    async function fetchJobs() {
      const endpoint = `${REACT_APP_CGN_API_URL}api/v1/jobs?page=${pageNumber}`;
      const headers = {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
        "Authorization": `Bearer ${REACT_APP_BEARER_TOKEN}`
      };

      await axios({
        url: endpoint,
        method: 'get',
        headers: headers
      }).then((response) => {
        // console.log(response.data.data);
        setJobsList(response.data.data);
        setPageDetails(response.data.meta);
        setDataReceived(true);
      }).catch((error) => {
        if (error.response) {
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
      });
    }
    fetchJobs();
  }, [pageNumber]);

  const nextPage = (event) => {
    const selectedPage = event;
    setPageNumber(selectedPage);
  };

  return (
    <>
      <div className="site-main">
        <Header />
        <div className="ttm-page-title-row">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading">
                    <h1 className="title">Open Positions</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="ttm-row career-section clearfix">
          <div className="container-xl">
            {/* <div className="row">
              <div className="col-md-9"> */}
            {!dataReceived ? (
              <div className="component-loader">
                {/* <ReactLoading
                type={"bubbles"}
                color={"#263045"}
                height={100}
                width={100}
                delay={2}
              /> */}
                <DotLottieReact
                  src="https://lottie.host/bb2a3ad1-cf1f-4c11-adc0-ba4c9f7dd6b3/BU0VVISlFx.lottie"
                  loop
                  autoplay
                  speed={1}
                  style={{ width: "300px", height: "200px", textAlign: "center" }}
                />
              </div>
            ) : (
              <React.Fragment>
                <div className="career-lists open-position-css mx-auto justify-content-center">
                  {jobsList
                    ?.map((jobItem, i) => (
                      <CareerItem jobItem={jobItem} key={i} />
                    ))}
                </div>
                {jobsList.length == 0 && dataReceived &&
                  <div className="text-center"><h3>Sorry, there are no open open positions at this time</h3></div>
                }
                {pageDetails && pageDetails.total > 10 &&
                  <div className="career-pages ttm-pagination">
                    <Pagination
                      activePage={pageDetails.current_page}
                      totalItemsCount={pageDetails.total}
                      itemsCountPerPage={pageDetails.per_page}
                      onChange={nextPage}
                      itemClass="page-item"
                      linkClass="page-numbers"
                      activeLinkClass="current"
                      firstPageText="First"
                      lastPageText="Last"
                    />
                  </div>
                }
              </React.Fragment>
            )}
            {/* </div>
            </div> */}
          </div>
        </section>
      </div>
      {showServiceSection && (
        <Footer />
      )}
    </>
  );
}