import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";

import axios from "axios";
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  Form,
  Alert,
  Row,
  Col
} from 'react-bootstrap';
import swal from 'sweetalert';

import Header from '../layout/Header';

export default function CareerApplyForm(props) {
  let { jobID } = useParams();
  const history = useHistory();
  const FILE_SIZE = 2097152;
  const SUPPORTED_FORMATS = [
    "application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ];
  const { REACT_APP_CGN_API_URL, REACT_APP_BEARER_TOKEN } = process.env;

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  return (
    <div className="site-main">
      <Header />
      <section className="ttm-row career-detail-section clearfix">
        <div className="container-xl">
          <div className="apply-job-form">
            <Formik
              initialValues={{ job_id: jobID, name: '', email: '', phone: '', location: '', resume: '', current_ctc: '', expected_ctc: '', joining_type: '', cover_letter: '' }}
              validateOnBlur={false}
              validationSchema={Yup.object().shape({
                name: Yup.string().required("Name is a required field").matches(/^[A-Za-z\s]+$/, 'Name must contain only letters'),
                email: Yup.string().email('This doesn’t look like a valid email').required("Email is a required field"),
                phone: Yup.string().required("Mobile number is a required field").matches(phoneRegExp, 'Mobile number is not valid'),
                location: Yup.string().required("Location is a required field"),
                resume: Yup.mixed().required('Resume is required for applying job')
                  .test(
                    "fileFormat",
                    "supports only PDF and DOCX",
                    value => value && SUPPORTED_FORMATS.includes(value.type)
                  )
                  .test(
                    "fileSize",
                    "File too large",
                    value => value && value.size <= FILE_SIZE
                  ),
                current_ctc: Yup.number()
                  .positive('CTC must be positive')
                  .integer('CTC must be an integer'),
                expected_ctc: Yup.number()
                  .required('Expected CTC is required')
                  .positive('CTC must be positive')
                  .integer('CTC must be an integer'),
                joining_type: Yup.string().required('Joining type is required'),
              })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                const formData = new FormData()
                formData.append("job_id", values.job_id)
                formData.append("name", values.name)
                formData.append("email", values.email)
                formData.append("phone", values.phone)
                formData.append("location", values.location)
                formData.append("resume", values.resume)
                formData.append("current_ctc", values.current_ctc)
                formData.append("expected_ctc", values.expected_ctc)
                formData.append("joining_type", values.joining_type)
                formData.append("cover_letter", values.cover_letter)

                const endpoint = `${REACT_APP_CGN_API_URL}api/v1/job/apply`;
                const headers = {
                  "content-type": "application/json",
                  "Authorization": `Bearer ${REACT_APP_BEARER_TOKEN}`
                };

                axios({
                  url: endpoint,
                  method: 'post',
                  data: formData,
                  headers: headers
                }).then((response) => {
                  if (response.data.success) {
                    swal("Thanks you!", response.data.message, "success").then(function () {
                      history.push("/careers");
                    });
                    setSubmitting(false);
                    resetForm();
                  }
                }).catch((error) => {
                  if (error.response) {
                  } else if (error.request) {
                    console.log(error.request);
                  } else {
                    console.log('Error', error.message);
                  }
                });
              }}
            >
              {({
                formik,
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
                setValues,
                isSubmitting
              }) => (
                <>
                  <h2>Apply for this job</h2>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group as={Row}>
                      <Col md={6}>
                        <div className="form-group">
                          <Field
                            type="text"
                            name="name"
                            className={(touched.name && errors.name) ? 'form-control is-invalid' : 'form-control bg-white border'}
                            placeholder="Your Name (*)"
                          />
                          <ErrorMessage name="name" className="invalid-feedback" component="div" />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group">
                          <Field
                            type="text"
                            name="email"
                            className={(touched.email && errors.email) ? 'form-control is-invalid' : 'form-control bg-white border'}
                            placeholder="Email Address (*)"
                          />
                          <ErrorMessage name="email" className="invalid-feedback" component="div" />
                        </div>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Col md={6}>
                        <div className="form-group">
                          <Field
                            type="text"
                            name="phone"
                            className={(touched.phone && errors.phone) ? 'form-control is-invalid' : 'form-control bg-white border'}
                            placeholder="Mobile"
                          />
                          <ErrorMessage name="phone" className="invalid-feedback" component="div" />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group">
                          <Field
                            type="text"
                            name="location"
                            className={(touched.location && errors.location) ? 'form-control is-invalid' : 'form-control bg-white border'}
                            placeholder="Location"
                          />
                          <ErrorMessage name="location" className="invalid-feedback" component="div" />
                        </div>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Col md={12}>
                        <div className="form-group">
                          <Field
                            type="file"
                            name="resume"
                            value={undefined}
                            onChange={(e) => {
                              setFieldValue(e.target.name, e.target.files[0])
                            }}
                            className={(touched.resume && errors.resume) ? 'form-control is-invalid' : 'form-control bg-white border'}
                            placeholder="Upload Resume"
                          />
                          <ErrorMessage name="resume" className="invalid-feedback" component="div" />
                        </div>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Col md={4}>
                        <div className="form-group">
                          <Field
                            type="number"
                            name="current_ctc"
                            className={(touched.current_ctc && errors.current_ctc) ? 'form-control is-invalid' : 'form-control bg-white border'}
                            placeholder="Current CTC"
                          />
                          <ErrorMessage name="current_ctc" className="invalid-feedback" component="div" />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="form-group">
                          <Field
                            type="number"
                            name="expected_ctc"
                            className={(touched.expected_ctc && errors.expected_ctc) ? 'form-control is-invalid' : 'form-control bg-white border'}
                            placeholder="Expected CTC"
                          />
                          <ErrorMessage name="expected_ctc" className="invalid-feedback" component="div" />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="form-group">
                          <Field as="select" name="joining_type" className={(touched.joining_type && errors.joining_type) ? 'form-control is-invalid' : 'form-control bg-white border'} >
                            <option value="">How early you can join?</option>
                            <option value="Immediately">Immediately</option>
                            <option value="1 Month">1 Month</option>
                            <option value="2 Month">2 Month</option>
                            <option value="3 Month">3 Month</option>
                            <option value="More than 3 months later">More than 3 months later</option>
                          </Field>
                          <ErrorMessage name="joining_type" className="invalid-feedback" component="div" />
                        </div>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Col md={12}>
                        <div className="form-group">
                          <Field
                            type="text"
                            name="cover_letter"
                            component="textarea"
                            rows="4"
                            className={(touched.cover_letter && errors.cover_letter) ? 'form-control is-invalid' : 'form-control bg-white border'}
                            placeholder="Cover Letter"
                          />
                          <ErrorMessage name="cover_letter" className="invalid-feedback" component="div" />
                        </div>
                      </Col>
                    </Form.Group>
                    <button type="submit" className="ttm-btn ttm-btn-size-sm rounded ttm-bgcolor-skincolor mr-4" disabled={isSubmitting}>
                      {isSubmitting ? "Please wait..." : "Apply"}
                    </button>
                    <button className="ttm-btn ttm-btn-size-sm rounded ttm-icon-btn-right" onClick={() => history.goBack()}>Back</button>
                    <Field type="hidden" name="job_id" />
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </div>
  );
}