import React, { Component } from "react";
import { MDBAnimation, MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask, MDBContainer, MDBRow } from
  "mdbreact";
import { Link } from 'react-router-dom';

export class CarouselPage extends Component {
  render() {
    return (
      <MDBRow>
        <div className="container-xl">
          <MDBCarousel
            activeItem={1}
            length={3}
            showControls={false}
            showIndicators={true}
            id="Home_banner"
            // interval={500000}
          >
            <MDBCarouselInner>
              <MDBCarouselItem className="" itemId="2">
                <MDBView className="">
                  <img class="d-block w-100 h-100" src="../../images/bannerimage2.jpg" alt="Second slide" loading="lazy"/>
                </MDBView>
                <MDBCarouselCaption className="container-xl banner4">
                  <div className="cog-banner ">
                    <div className="carousel-caption-inner banner4">
                      {/* <MDBAnimation className="slideInUp" count={1} >
                       
                       <p className=" mb-10"> <span className='cog'>COGN<span className='cog1'>iTUDE</span> </span> Technologies - Software & IT Consulting </p>
                     </MDBAnimation> */}
                      <MDBAnimation className="slideInUp" count={1} >

                        <h1 class="display-5 text-white ttm-h1-font">Legacy to Digital </h1>
                      </MDBAnimation>
                      <MDBAnimation className="slideInUp" count={1} >
                        <p className="ttm-p-font text-white">Transform digitally, compete globally</p>
                      </MDBAnimation>
                      <MDBAnimation>
                      </MDBAnimation>
                    </div>
                  </div>
                </MDBCarouselCaption>
              </MDBCarouselItem>
              <MDBCarouselItem className="" itemId="1">
                <MDBView className="">
                  <img class="d-block w-100 h-100" src="../../images/bannerimage1.jpg" alt="Second slide" loading="eager"/>
                </MDBView>
                <MDBCarouselCaption className="container-xl banner5">
                  <div className="cog-banner">
                    <div className="carousel-caption-inner banner5">
                      {/* <MDBAnimation className="slideInUp" count={1} >
                       
                       <p className=" mb-10"> <span className='cog'>COGN<span className='cog1'>iTUDE</span> </span> Technologies - Software & IT Consulting </p>
                     </MDBAnimation> */}
                      <MDBAnimation className="slideInUp" count={1} >
                        <h1 class="display-5 text-white ttm-h1-font">Bridging <br></br> Business <br></br> & Technology </h1>
                      </MDBAnimation>
                      <MDBAnimation className="slideInUp" count={1} >
                        <p className="ttm-p-font text-white"> Revolutionizing business <br></br> with the power of generative intelligence </p>

                      </MDBAnimation>
                    </div>
                  </div>
                </MDBCarouselCaption>
              </MDBCarouselItem>
              <MDBCarouselItem className="" itemId="3">
                <MDBView>
                  <img class="d-block w-100 h-100" src="../../images/bannerimage3.jpg" alt="Second slide" loading="lazy"/>
                </MDBView>
                <MDBCarouselCaption className="container-xl banner6">
                  <div className="cog-banner">
                    <div className="carousel-caption-inner banner6">
                      {/* <MDBAnimation className="slideInUp" count={1} >
                       
                       <p className=" mb-10"> <span className='cog'>COGN<span className='cog1'>iTUDE</span> </span> Technologies - Software & IT Consulting </p>
                     </MDBAnimation> */}
                      <MDBAnimation className="slideInUp" count={1} >
                        <div className="d-flex flex-row-reverse">
                          <h1 class="display-5 text-white ttm-h1-font-banner6">Mobile Apps for <br></br> a Connected World</h1>
                        </div>
                      </MDBAnimation>
                      <MDBAnimation className="slideInUp" count={1} >
                      <div className="d-flex flex-row-reverse">
                        <p className="ttm-p-font text-white"> Unleashing power in the palm of your hands</p>
                        </div>
                      </MDBAnimation>
                    </div>
                  </div>
                </MDBCarouselCaption>
              </MDBCarouselItem>

            </MDBCarouselInner>
          </MDBCarousel>
        </div>
      </MDBRow>
    );
  }
}

export default CarouselPage;