import React, { Component } from 'react'

export class Footer extends Component {
  render() {
    return (
      <footer className="footer widget-footer clearfix" style={{ marginTop: 40 }}>
        {this.props.children}
        <div className="first-footer ttm-bgcolor-skincolor ttm-bg ttm-bgimage-yes bg-img1">
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
          <div className="container-xl">
            <div className="row align-items-center justify-content-start">
              <div className="col d-lg-none d-md-none d-block">
                <div className="text-left">
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box left-icon icon-align-top">
                    <div className="featured-icon">{/*  featured-icon */}
                      <div className="ttm-icon ttm-textcolor-white ttm-icon_element-size-md">
                        <i className="ti ti-location-pin" />{/*  ttm-icon */}
                      </div>
                    </div>
                  </div>{/*  featured-icon-box END */}
                </div>
              </div>
            </div>
            <div className="row align-items-md-center">
              <div className="col-lg-2 col-md-3 col-sm-6 order-md-1 res-767-mt-20 d-lg-block d-md-block d-none">
                <div className="text-left">
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box left-icon icon-align-top">
                    <div className="featured-icon">{/*  featured-icon */}
                      <div className="ttm-icon ttm-textcolor-white ttm-icon_element-size-md">
                        <i className="ti ti-location-pin" />{/*  ttm-icon */}
                      </div>
                    </div>
                    <div className="featured-content">{/*  featured-content */}
                      <div className="featured-desc">
                        <p>Chennai, India</p>
                      </div>
                    </div>
                  </div>{/*  featured-icon-box END */}
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-6 order-md-1 res-767-mt-20 d-lg-none d-md-none">
                <div className="text-left">
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box left-icon icon-align-top">

                    <div className="featured-content">{/*  featured-content */}
                      <div className="featured-desc">
                        <p>Chennai, India</p>
                      </div>
                    </div>
                  </div>{/*  featured-icon-box END */}
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-6 order-md-1 res-767-mt-20">
                <div className="text-left">
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box left-icon icon-align-top">

                    <div className="featured-content">{/*  featured-content */}
                      <div className="featured-desc">
                        <p>Dusseldorf, Germany</p>
                      </div>
                    </div>
                  </div>{/*  featured-icon-box END */}
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-6 order-md-1 res-767-mt-20">
                <div className="text-left">
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box left-icon icon-align-top">

                    <div className="featured-content">{/*  featured-content */}
                      <div className="featured-desc">
                        <p>Carolina, USA</p>
                      </div>
                    </div>
                  </div>{/*  featured-icon-box END */}
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-6 order-md-1 res-767-mt-20">
                <div className="text-left">
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box left-icon icon-align-top">

                    <div className="featured-content">{/*  featured-content */}
                      <div className="featured-desc">
                        <p>Nigeria, West Africa</p>
                      </div>
                    </div>
                  </div>{/*  featured-icon-box END */}
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-6 order-md-3 res-767-mt-20 d-lg-block d-none">
                <div className="text-sm-right">
                  <a className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white"><i className="far fa-envelope" /> info@cognitude.co.in</a>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-center mt-4">
              <div className="res-767-mt-20 d-lg-none">
                <div className="text-sm-right">
                  <a className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white"><i className="far fa-envelope" /> info@cognitude.co.in</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-footer-text ttm-bgcolor-darkgrey ttm-textcolor-white">
          <div className="container-xl">
            <div className="row copyright justify-content-center res-767-text-center">
              <div className="col-md-6 text-center">
                <div>
                  <span>Copyright © {new Date().getFullYear()}&nbsp;Cognitude Consultancy Private Limited</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}